<template>
    <div >
  <v-app >
  
    <dashboard-core-app-bar />

    <dashboard-core-drawer v-if="authLog"/>

    <dashboard-core-view  />
  
    <!-- <dashboard-core-settings /> -->
  </v-app>
</div>
</template>

<script>
export default {
  name: "DashboardIndex",

  components: { 
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    DashboardCoreDrawer: () => import("./components/core/Drawer"),
    DashboardCoreSettings: () => import("./components/core/Settings"),
    DashboardCoreView: () => import("./components/core/View"),
  },

  data: () => ({
    expandOnHover: false,
    authLog: false,
  }),
  watch: {
    "$route.path" : function() {
      console.log("lkjgkhjghjfhjfklhjkgjhkfgjhkfgjkgjhfgjhkfl");
      console.log("lkjgkhjghjfhjfklhjkgjhkfgjhkfgjkgjhfgjhkfl");

    console.log(this.$route.path);
    console.log("lkjgkhjghjfhjfklhjkgjhkfgjhkfgjkgjhfgjhkfl");
    console.log("lkjgkhjghjfhjfklhjkgjhkfgjhkfgjkgjhfgjhkfl");
  } 
  },

  created() {
    console.log("dfsdfsdfsdfsdfsdfsdfsddfsdffsddfsdf");
    console.log("dfsdfsdfsdfsdfsdfsdfsddfsdffsddfsdf");
    console.log(this.$route.path);
    console.log("dfsdfsdfsdfsdfsdfsdfsddfsdffsddfsdf");
    console.log("dfsdfsdfsdfsdfsdfsdfsddfsdffsddfsdf");


    if (this.$i18n.locale == 'ar') {
      this.$vuetify.rtl = true
    } else {
      this.$vuetify.rtl = false
    }
    if(localStorage.getItem('access_token')) this.authLog = true;

  }
};
</script>
<style >
/* .application_background {
  background: #6886a7 !important;
} */
</style>